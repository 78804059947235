import React from "react";
import "./Menu.css";
import { Link } from "react-router-dom";

export const Menu = () => {
  return (
    <div className="menu">
      <div className="left-container">
        <Link to="/about" className="link">
          <h2 className="menu-text">About</h2>
        </Link>
        <Link to="/portfolio" className="link">
          <h2 className="menu-text">Portfolio</h2>
        </Link>
        <Link to="/photos" className="link">
          <h2 className="menu-text">Photos</h2>
        </Link>
        <Link to="/archive" className="link">
          <h2 className="menu-text">Archive</h2>
        </Link>
        <Link to="/contact" className="link">
          <h2 className="menu-text">Contact</h2>
        </Link>
      </div>
      <div className="right-container">
        <a
          target="_blank"
          href="https://www.instagram.com/han.nah.lee"
          className="link"
          rel="noreferrer"
        >
          <h2 className="social-text">instagram</h2>
        </a>
        <a
          target="_blank"
          href="https://www.linkedin.com/in/hhannahlee"
          className="link"
          rel="noreferrer"
        >
          <h2 className="social-text">linkedin</h2>
        </a>
        <a
          target="_blank"
          href="https://dribbble.com/leehannah"
          className="link"
          rel="noreferrer"
        >
          <h2 className="social-text">dribbble</h2>{" "}
        </a>
        <br />
      </div>
    </div>
  );
};

export default Menu;
