import React from "react";
// import "./Portfolio.css";
import { Link } from "react-router-dom";
import HeaderLight from "../HeaderLight";
import FooterLight from "../FooterLight";

export const Projects = () => {
  return (
    <div className="archive-main">
      <div className="main">
        <HeaderLight />
        <div className="section">
          <div className="coming-soon">COMING SOON</div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
