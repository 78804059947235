import React from "react";
import "../portfolio/PortfolioPage.css";
import HeaderLight from "../HeaderLight";
import FooterLight from "../FooterLight";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export const Allkpop = () => {
  return (
    <div className="main">
      <HeaderLight />
      <div className="section">
        <div className="upper-title">© ALLKPOP</div>
        <div className="preview">The most-trafficked K-pop news site</div>
        <div className="bottom-details">2017 &nbsp; — &nbsp; Hannah Lee</div>
        <img
          src={require("../images/ALLKPOP/STRAYKIDS/SK16.png")}
          className="main-slide"
        ></img>

        <div className="slides-container">
          <Carousel
            dynamicHeight={true}
            centerMode={true}
            centerSlidePercentage={30}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            autoPlay={true}
            interval={2000}
          >
            <img
              src={require("../images/ALLKPOP/SIKK.jpg")}
              className="slide"
            ></img>
            <img
              src={require("../images/ALLKPOP/JAMESXKEVIN.jpg")}
              className="slide"
            ></img>
            <img
              src={require("../images/ALLKPOP/SEVENTEEN/SVT2.png")}
              className="slide"
            ></img>
            <img
              src={require("../images/ALLKPOP/GANGDONGWON.jpeg")}
              className="slide"
            ></img>
            <img
              src={require("../images/ALLKPOP/LAY.jpg")}
              className="slide"
            ></img>
            <img
              src={require("../images/ALLKPOP/REDVELVET.jpg")}
              className="slide"
            ></img>
            <img
              src={require("../images/ALLKPOP/NCT.jpg")}
              className="slide"
            ></img>
            <img
              src={require("../images/ALLKPOP/SF9.jpg")}
              className="slide"
            ></img>
            <img
              src={require("../images/ALLKPOP/BEWHY/B7.png")}
              className="slide"
            ></img>
            <img
              src={require("../images/ALLKPOP/ZICO.jpg")}
              className="slide"
            ></img>
          </Carousel>
        </div>
        <div style={{ paddingBottom: "2%" }}></div>
        <div className="slides-container">
          <div className="artist-names">
            STRAY KIDS &nbsp; <span>&#183;</span> &nbsp; SEVENTEEN &nbsp;{" "}
            <span>&#183;</span> &nbsp; NCT &nbsp; <span>&#183;</span> &nbsp; RED
            VELVET &nbsp; <span>&#183;</span> &nbsp; ERIC NAM &nbsp;{" "}
            <span>&#183;</span> &nbsp; ZICO &nbsp; <span>&#183;</span> &nbsp;
            LAY &nbsp; <span>&#183;</span> &nbsp; SIK-K &nbsp;{" "}
            <span>&#183;</span> &nbsp; SF9 &nbsp; <span>&#183;</span> &nbsp;
            GANG DONG WON &nbsp; <span>&#183;</span> &nbsp; JAMES LEE
          </div>
        </div>
        <div className="indiv-description-container">
          <div className="indiv-description">
            Catering to over 13 million monthly readers worldwide, over 90
            million monthly visits, and serving over 130 million page views per
            month, allkpop is the most trafficked English based K-pop news site
            in the world.
            <br />
            <br />
            As the Content Feature Writer and Video Producer for allkpop, I have
            had the opportunity to work with numerous K-pop artists and notable
            figures in the Korean entertainment industry.
            <br />
            <br />
            <hr></hr>
            <span
              style={{
                fontSize: "1.2vw",
                fontWeight: 400,
              }}
            >
              Journalism &nbsp; <span>&#183;</span> &nbsp; Photography &nbsp;
              <span>&#183;</span> &nbsp; Marketing
            </span>
          </div>
        </div>
      </div>
      <FooterLight />
      <div style={{ paddingBottom: "2%" }}></div>
    </div>
  );
};

export default Allkpop;
