import React from "react";
import { AppBar, Toolbar } from "@mui/material";
import { Link } from "react-router-dom";
import "./pages/Home/Home.css";

export const HeaderDark = (props) => {
  const displayDesktop = () => {
    return (
      <Toolbar className="header-dark">
        <div className="header-dark-title">
          <Link to="/" className="link">
            {/* <h1 className="header-light-title">hl</h1> */}
            <img
              src={require("./images/H_B.webp")}
              className="inflatable-letter-h"
              alt="letter"
            ></img>
            <img
              src={require("./images/L_B.webp")}
              className="inflatable-letter-l"
              alt="letter"
            ></img>
          </Link>
        </div>
        <div className="header-dark-title">
          <Link to="/menu" className="link">
            {/* <h1 className="header-light-title">menu</h1> */}
            <img
              src={require("./images/M_B.webp")}
              className="inflatable-letter-m"
              alt="letter"
            ></img>
            <img
              src={require("./images/E_B.webp")}
              className="inflatable-letter-e"
              alt="letter"
            ></img>
            <img
              src={require("./images/N_B.webp")}
              className="inflatable-letter-n"
              alt="letter"
            ></img>
            <img
              src={require("./images/U_B.webp")}
              className="inflatable-letter-u"
              alt="letter"
            ></img>
          </Link>
        </div>
      </Toolbar>
    );
  };

  return (
    <header>
      <AppBar elevation={0} color={"transparent"}>
        {displayDesktop()}
      </AppBar>
    </header>
  );
};

export default HeaderDark;
