import React from "react";
import "./About.css";
import HeaderLight from "../../HeaderLight";

export const About = () => {
  return (
    <div className="about">
      <HeaderLight />
      <section id="intro" className="section-intro-page">
        <div className="bigtext-container">
          <h1 className="intro-text">Designer.</h1>
          <h1 className="intro-text">Creator.</h1>
          <h1 className="intro-text">Developer.</h1>
        </div>
        <div className="smalltext-container">
          <p className="intro-blurb">
            I am a student at Stanford University studying Computer Science.
          </p>
        </div>
      </section>
      <section id="photos" className="section-photos-page">
        <img
          src={require("../../images/PROFILE.JPG")}
          className="photo"
          alt="profile"
        ></img>
        <img
          src={require("../../images/PROFILE.JPG")}
          className="photo"
          alt="profile"
        ></img>
        <img
          src={require("../../images/PROFILE.JPG")}
          className="photo"
          alt="profile"
        ></img>
        <img
          src={require("../../images/PROFILE.JPG")}
          className="photo"
          alt="profile"
        ></img>
        <img
          src={require("../../images/PROFILE.JPG")}
          className="photo"
          alt="profile"
        ></img>
      </section>
      <section id="details" className="section-details-page">
        <div className="details-container">
          <h3 className="details-title">Tools</h3>
          <p className="details-indiv">Figma</p>
          <p className="details-indiv">Adobe Illustrator</p>
          <p className="details-indiv">Adobe InDesign</p>
          <p className="details-indiv">Adobe Lightroom</p>
          <p className="details-indiv">Final Cut Pro</p>
        </div>
        <div className="details-container">
          <h3 className="details-title">Languages</h3>
          <p className="details-indiv">JavaScript</p>
          <p className="details-indiv">Python</p>
          <p className="details-indiv">C / C++</p>
          <p className="details-indiv">R</p>
        </div>
        <div className="details-container">
          <h3 className="details-title">SKILLS</h3>
          <p className="details-indiv">Software Engineering</p>
          <p className="details-indiv">UI / UX Design</p>
          <p className="details-indiv">Leadership</p>
          <p className="details-indiv">Entrepreneurship</p>
        </div>
      </section>
      {/* <section id="etc" className="section-etc-page">
        <div className="x1">
          <div className="y">
            <h1 className="details-title">BARRY'S BOOTCAMP</h1>
          </div>
        </div>
        <div className="x2">
          <div className="y">
            <h1 className="details-title">TRADER JOE'S</h1>
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default About;
