import React from "react";
import "./PhotosPage.css";
import HeaderDark from "../HeaderDark";
import FooterDark from "../FooterDark";

export const BewhY = () => {
  return (
    <div className="main-photos">
      <HeaderDark />
      <div className="half-section-container">
        <div className="half-section-left">
          <img
            src={require("../images/ALLKPOP/BEWHY/B17.png")}
            className="allkpop-half-photo"
          ></img>
          <img
            src={require("../images/ALLKPOP/BEWHY/B7.png")}
            className="allkpop-half-photo"
          ></img>
          <img
            src={require("../images/ALLKPOP/BEWHY/B16.png")}
            className="allkpop-half-photo"
          ></img>
          <img
            src={require("../images/ALLKPOP/BEWHY/TP2.jpg")}
            className="allkpop-half-photo"
          ></img>
        </div>
        <div className="half-section-right">
          <img
            src={require("../images/ALLKPOP/BEWHY/B14.png")}
            className="allkpop-half-photo"
          ></img>
          <img
            src={require("../images/ALLKPOP/BEWHY/B21.png")}
            className="allkpop-half-photo"
          ></img>
          <img
            src={require("../images/ALLKPOP/BEWHY/TP.png")}
            className="allkpop-half-photo"
          ></img>
        </div>
      </div>
      <FooterDark />
      <div style={{ paddingBottom: "2%" }}></div>
    </div>
  );
};

export default BewhY;
