import React from "react";
import "./PhotosPage.css";
import HeaderDark from "../HeaderDark";
import FooterDark from "../FooterDark";

export const NCT = () => {
  return (
    <div className="main-photos">
      <HeaderDark />
      <div className="full-section">
        <img
          src={require("../images/ALLKPOP/NCT.jpg")}
          className="allkpop-full-photo"
          alt="nct"
        ></img>
      </div>
      <FooterDark />
      <div style={{ paddingBottom: "2%" }}></div>
    </div>
  );
};

export default NCT;
