import React from "react";
import "./pages/Home/Home.css";
import Marquee from "react-fast-marquee";

export const FooterLight = () => {
  return (
    <div className="marquee-light">
      <Marquee speed={80}>
        hhannah@stanford.edu &nbsp;
        <span style={{ fontSize: "120%" }}>☺</span> &nbsp; hhannah@stanford.edu
        &nbsp; <span style={{ fontSize: "120%" }}>☺</span> &nbsp;
      </Marquee>
    </div>
  );
};

export default FooterLight;
