import React from "react";
import "./PhotosPage.css";
import HeaderDark from "../HeaderDark";
import FooterDark from "../FooterDark";

export const StrayKids = () => {
  return (
    <div className="main-photos">
      <HeaderDark />
      <div className="half-section-container">
        <div className="half-section-left">
          <img
            src={require("../images/ALLKPOP/STRAYKIDS/SK1.png")}
            className="allkpop-half-photo"
            alt="straykids"
          ></img>
          <img
            src={require("../images/ALLKPOP/STRAYKIDS/SK14.png")}
            className="allkpop-half-photo"
            alt="straykids"
          ></img>
          <img
            src={require("../images/ALLKPOP/STRAYKIDS/SK15.png")}
            className="allkpop-half-photo"
            alt="straykids"
          ></img>
          <img
            src={require("../images/ALLKPOP/STRAYKIDS/SK6.png")}
            className="allkpop-half-photo"
            alt="straykids"
          ></img>
          <img
            src={require("../images/ALLKPOP/STRAYKIDS/SK8.png")}
            className="allkpop-half-photo"
            alt="straykids"
          ></img>
        </div>
        <div className="half-section-right">
          <img
            src={require("../images/ALLKPOP/STRAYKIDS/SK9.png")}
            className="allkpop-half-photo"
            alt="straykids"
          ></img>
          <img
            src={require("../images/ALLKPOP/STRAYKIDS/SK12.png")}
            className="allkpop-half-photo"
            alt="straykids"
          ></img>
          <img
            src={require("../images/ALLKPOP/STRAYKIDS/SK13.png")}
            className="allkpop-half-photo"
            alt="straykids"
          ></img>
          <img
            src={require("../images/ALLKPOP/STRAYKIDS/SK5.png")}
            className="allkpop-half-photo"
            alt="straykids"
          ></img>
        </div>
      </div>
      <FooterDark />
      <div style={{ paddingBottom: "2%" }}></div>
    </div>
  );
};

export default StrayKids;
