import React from "react";
import "./PortfolioPage.css";
import HeaderLight from "../HeaderLight";
import FooterLight from "../FooterLight";

export const Cliquerie = () => {
  return (
    <div className="main">
      <HeaderLight />
      <div className="section">
        <div className="upper-title">© CLIQUERIE</div>
        <div className="preview">The better way to achieve goals</div>
        <div className="bottom-details">2022 &nbsp; — &nbsp; Hannah Lee</div>
        <img
          src={require("../images/CLIQUERIE.webp")}
          style={{ width: "90%", alignSelf: "center" }}
        ></img>
        <div className="indiv-description-container">
          <div className="indiv-description">
            We've all set the New Year's resolution to wake up at 6:00 am, or go
            to the gym, or drink 2L of water. And... we've also all fallen
            through with our New Year's resolution at least once (or perhaps
            more than once). It's hard to keep on track with our goals, but it's
            not necessarily our problem.
            <br />
            <br />
            One of the primary reasons why it is so hard to create lasting
            change is due to the lack of self monitoring and motivation. What
            better way is there to stay motivated than to have your friends keep
            you accountable?
            <br />
            <br />
            Through the Generation She program, I have gotten the opportunity to
            redesign the platform and introducing new features to help improve
            the user experience. Here are some changes I made:
            <ul>
              <li className="bulleted-list">Redesign app and website</li>
              <li className="bulleted-list">Update color scheme</li>
              <li className="bulleted-list">Optimize font pairings</li>
              <li className="bulleted-list">Introduce new features</li>
            </ul>
            <hr></hr>
            <span
              style={{
                fontSize: "1.2vw",
                fontWeight: 400,
              }}
            >
              Figma &nbsp; <span>&#183;</span> &nbsp; Adobe Illustrator
              <br />
              <span style={{ fontStyle: "italic" }}>
                For more information about this project, please contact
                hhannah@stanford.edu
              </span>
            </span>
          </div>
        </div>
      </div>
      <FooterLight />
      <div style={{ paddingBottom: "2%" }}></div>
    </div>
  );
};

export default Cliquerie;
