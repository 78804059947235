/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./Portfolio.css";
import { Link } from "react-router-dom";
import HeaderLight from "../../HeaderLight";
import FooterLight from "../../FooterLight";

export const Design = () => {
  return (
    <div className="portfolio">
      <HeaderLight />
      <div className="section-portfolio">
        <Link to="/lovealarm" className="title-link">
          <div className="portfolio-title">
            LOVE ALARM{" "}
            <img
              src={require("../../images/ARROW.webp")}
              className="arrow"
            ></img>
            &nbsp;
          </div>
        </Link>
        <img
          src={require("../../images/LOVE-ALARM.webp")}
          className="main-photo"
        ></img>
        <div className="description-container">
          <div className="empty-container">
            <div className="small-title">Love Alarm</div>
          </div>
          <div className="empty-container">
            <div className="description">
              Creation and full stack development of a dating app based on the
              concept of love at first sight
            </div>
          </div>
        </div>
      </div>
      <hr className="horizontal-line" />
      <div className="section-portfolio">
        <Link to="/cake" className="title-link">
          <div className="portfolio-title">
            CAKE{" "}
            <img
              src={require("../../images/ARROW.webp")}
              className="arrow"
            ></img>
            &nbsp;
          </div>
        </Link>
        <img
          src={require("../../images/CAKE.webp")}
          className="main-photo"
        ></img>
        <div className="description-container">
          <div className="empty-container">
            <div className="small-title">CAKE</div>
          </div>
          <div className="empty-container">
            <div className="description">
              Ideation and design for an app that revolutionizes the way we shop
            </div>
          </div>
        </div>
      </div>
      <hr className="horizontal-line" />
      <div className="section-portfolio">
        <Link to="/cliquerie" className="title-link">
          <div className="portfolio-title">
            CLIQUERIE{" "}
            <img
              src={require("../../images/ARROW.webp")}
              className="arrow"
            ></img>
            &nbsp;
          </div>
        </Link>
        <img
          src={require("../../images/CLIQUERIE.webp")}
          className="main-photo"
        ></img>
        <div className="description-container">
          <div className="empty-container">
            <div className="small-title">CLIQUERIE</div>
          </div>
          <div className="empty-container">
            <div className="description">
              Redesign for an app that introduces the better way to achieve
              goals
            </div>
          </div>
        </div>
      </div>
      <hr className="horizontal-line" />
      <div className="section-portfolio">
        <Link to="/tumblr" className="title-link">
          <div className="portfolio-title">
            TUMBLR{" "}
            <img
              src={require("../../images/ARROW.webp")}
              className="arrow"
            ></img>
            &nbsp;
          </div>
        </Link>
        <img
          src={require("../../images/TUMBLR.webp")}
          className="main-photo"
        ></img>
        <div className="description-container">
          <div className="empty-container">
            <div className="small-title">TUMBLR</div>
          </div>
          <div className="empty-container">
            <div className="description">
              Logo redesign for Dribble's "Design a new Tumblr app icon" playoff
            </div>
          </div>
        </div>
      </div>
      <hr className="horizontal-line" />
      <div className="section-portfolio">
        <Link to="/picnic" className="title-link">
          <div className="portfolio-title">
            PICNIC{" "}
            <img
              src={require("../../images/ARROW.webp")}
              className="arrow"
            ></img>
            &nbsp;
          </div>
        </Link>
        <img
          src={require("../../images/PICNIC.webp")}
          className="main-photo"
        ></img>
        <div className="description-container">
          <div className="empty-container">
            <div className="small-title">PICNIC</div>
          </div>
          <div className="empty-container">
            <div className="description">
              App and logo redesign for an app that connects people who share
              interests
            </div>
          </div>
        </div>
      </div>
      <FooterLight />
      <div style={{ paddingBottom: "2%" }}></div>
    </div>
  );
};

export default Design;
