import React from "react";
import "../portfolio/PortfolioPage.css";
import HeaderLight from "../HeaderLight";
import FooterLight from "../FooterLight";

export const ElEstoque = () => {
  return (
    <div className="main">
      <HeaderLight />
      <div className="section">
        <div className="upper-title">© EL ESTOQUE</div>
        <div className="preview">National award-winning publication</div>
        <div className="bottom-details">2017 &nbsp; — &nbsp; Hannah Lee</div>
        <img
          src={require("../images/ELESTOQUE.jpg")}
          className="main-slide"
        ></img>
      </div>
      <div className="indiv-description-container">
        <div className="indiv-description">
          El Estoque is a national award-winning student publication that has
          reported on and for Monta Vista High School in Cupertino, CA since
          1969.
          <br />
          <br />
          <em>
            Principles of Business. Writing for Publication. Principles of
            Business. Writing for Publication. Principles of Business. Writing
            for Publication.
          </em>
          <br />
          <br />
          And that’s how it started — after a quick game of He Loves Me … He
          Loves Me Not (elective edition), I hesitantly scribbled down Writing
          for Publication onto my course selection form. Little did I know that
          Writing for Publication would bring me into the world of journalism
          and open up avenues for me to discover my passions.
          <br />
          <br />
          From a scared staff writer unable to adapt to AP style, I transitioned
          to the Arts and Entertainment editor and eventually led the
          publication as the Editor-in-chief.
          <br />
          <br />
          <a
            target="_blank"
            href="https://elestoque.org/staff_name/hannah-lee/"
            className="link"
          >
            <div className="external-link-title">→ READ STORIES</div>
          </a>
          <hr></hr>
          <span
            style={{
              fontSize: "1.2vw",
              fontWeight: 400,
            }}
          >
            Journalism &nbsp; <span>&#183;</span> &nbsp; Photography &nbsp;
            <span>&#183;</span> &nbsp; Marketing
          </span>
        </div>
      </div>
      <FooterLight />
      <div style={{ paddingBottom: "2%" }}></div>
    </div>
  );
};

export default ElEstoque;
