import React from "react";
import "./PortfolioPage.css";
import HeaderLight from "../HeaderLight";
import FooterLight from "../FooterLight";

export const Picnic = () => {
  return (
    <div className="main">
      <HeaderLight />
      <div className="section">
        <div className="upper-title">© PICNIC</div>
        <div className="preview">So, tell me — what's your interest?</div>
        <div className="bottom-details">2022 &nbsp; — &nbsp; Hannah Lee</div>
        <img
          src={require("../images/PICNIC.webp")}
          className="main-slide"
        ></img>
        <div className="indiv-description-container">
          <div className="indiv-description">
            Everyone has an interest — what's yours? We all have interests.
            Maybe it's a guilty pleasure. Or conversely, your identity :)
            <br />
            <br />
            Picnic is an app that allows you to scroll through content curated
            around your interest in a TikTok style feed and meet people who love
            to nerd our about sports, or K-Pop, or beekeeping just as much as
            you do!
            <br />
            <br />
            In 2020, I joined Picnic as the Chief Marketing Officer. But as with
            any startup, I found myself putting on many different hats. Aside
            from the marketing I did, I was responsible for redesigning the app
            and logo for the platform.
            <br />
            <br />
            <hr></hr>
            <span
              style={{
                fontSize: "1.2vw",
                fontWeight: 400,
              }}
            >
              Figma &nbsp; <span>&#183;</span> &nbsp; Adobe Illustrator
              <br />
              <span style={{ fontStyle: "italic" }}>
                For more information about this project, please contact
                hhannah@stanford.edu
              </span>
            </span>
          </div>
        </div>
      </div>
      <FooterLight />
      <div style={{ paddingBottom: "2%" }}></div>
    </div>
  );
};

export default Picnic;
