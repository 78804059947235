import React from "react";
import "./PhotosPage.css";
import HeaderDark from "../HeaderDark";
import FooterDark from "../FooterDark";

export const Seventeen = () => {
  return (
    <div className="main-photos">
      <HeaderDark />
      <div className="full-section">
        <img
          src={require("../images/ALLKPOP/SEVENTEEN/SVT2.png")}
          className="allkpop-full-photo"
          alt="seventeen"
        ></img>
        <img
          src={require("../images/ALLKPOP/SEVENTEEN/SVT1.png")}
          className="allkpop-full-photo"
          alt="seventeen"
        ></img>
      </div>
      <FooterDark />
      <div style={{ paddingBottom: "2%" }}></div>
    </div>
  );
};

export default Seventeen;
