import React from "react";
import "./PortfolioPage.css";
import HeaderLight from "../HeaderLight";
import FooterLight from "../FooterLight";

export const LoveAlarm = () => {
  return (
    <div className="main">
      <HeaderLight />
      <div className="section">
        <div className="upper-title">© LOVE ALARM</div>
        <div className="preview">Love at first sight exists</div>
        <div className="bottom-details">2022 &nbsp; — &nbsp; Hannah Lee</div>
        <img
          src={require("../images/CLIQUERIE.webp")}
          style={{ width: "90%", alignSelf: "center" }}
        ></img>
        <div className="indiv-description-container">
          <div className="indiv-description">
            Love at first sight exists — everyday, we cross paths with hundreds
            of people. Have you ever walked into a restaurant and seen a guy
            sitting at the table next to you with his friend and thought, “Wow,
            he’s cute.” Or perhaps sat in a lecture and looked to your right to
            see your ideal type jotting down notes?
            <br />
            <br />
            It’s these candid, but genuine interactions that are representative
            of who we are truly interested in — not the person you swipe right
            on without much thought. Why then is it that these moments rarely
            lead to something real? In our current society where we have been
            shaped to be hypersensitive to other people’s perceptions of us, it
            has become harder to walk up to someone and ask for their number or
            tell them you are interested in them — in fact, doing so might
            prompt social scrutiny.
            <br />
            <br />
            Inspired by the popular Netflix series, I designed an app that
            allows users to ring the “love alarm” and send a message to a person
            they are interested in within their radius. Real love starts now.
            <br />
            <br />
            <hr></hr>
            <span
              style={{
                fontSize: "1.2vw",
                fontWeight: 400,
              }}
            >
              React Native &nbsp; <span>&#183;</span> &nbsp; Supabase &nbsp;{" "}
              <span>&#183;</span> &nbsp; Scaledrone &nbsp; <span>&#183;</span>{" "}
              &nbsp; Figma &nbsp; <span>&#183;</span> &nbsp; Adobe Illustrator
              <br />
              <span style={{ fontStyle: "italic" }}>
                For more information about this project, please contact
                hhannah@stanford.edu
              </span>
            </span>
          </div>
        </div>
      </div>
      <FooterLight />
      <div style={{ paddingBottom: "2%" }}></div>
    </div>
  );
};

export default LoveAlarm;
