import React from "react";
import "./PhotosPage.css";
import HeaderDark from "../HeaderDark";
import FooterDark from "../FooterDark";

export const AOMG = () => {
  return (
    <div className="main-photos">
      <HeaderDark />
      <div className="half-section-container">
        <div className="half-section-left">
          <img
            src={require("../images/ALLKPOP/AOMG/AOMG1.PNG")}
            className="allkpop-half-photo"
            alt="aomg"
          ></img>
          <img
            src={require("../images/ALLKPOP/AOMG/AOMG5.PNG")}
            className="allkpop-half-photo"
            alt="aomg"
          ></img>
          <img
            src={require("../images/ALLKPOP/AOMG/AOMG7.PNG")}
            className="allkpop-half-photo"
            alt="aomg"
          ></img>
        </div>
        <div className="half-section-right">
          <img
            src={require("../images/ALLKPOP/AOMG/AOMG4.PNG")}
            className="allkpop-half-photo"
            alt="aomg"
          ></img>{" "}
          <img
            src={require("../images/ALLKPOP/AOMG/AOMG2.PNG")}
            className="allkpop-half-photo"
            alt="aomg"
          ></img>
          <img
            src={require("../images/ALLKPOP/AOMG/AOMG6.PNG")}
            className="allkpop-half-photo"
            alt="aomg"
          ></img>
          <img
            src={require("../images/ALLKPOP/AOMG/AOMG3.PNG")}
            className="allkpop-half-photo"
            alt="aomg"
          ></img>
        </div>
      </div>
      <FooterDark />
      <div style={{ paddingBottom: "2%" }}></div>
    </div>
  );
};

export default AOMG;
