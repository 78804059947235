import React from "react";
import "./Contact.css";
import { Link } from "react-router-dom";
import HeaderDark from "../../HeaderDark";
import FooterDark from "../../FooterDark";
import ContactForm from "./ContactForm";

export const Contact = () => {
  return (
    <div className="contact">
      <HeaderDark />
      <div className="container">
        <h1 className="contact-title">LET'S GET IN TOUCH</h1>
        {/* <ContactForm /> */}
      </div>
      <FooterDark />
      <div style={{ paddingBottom: "2%" }}></div>
    </div>
  );
};

export default Contact;
