import React from "react";
import "./Archive.css";
import { Link } from "react-router-dom";
import HeaderLight from "../../HeaderLight";
import FooterLight from "../../FooterLight";

export const Archive = () => {
  return (
    <div className="archive">
      <HeaderLight />
      <div className="section-archive">
        <div style={{ paddingTop: "3%" }}></div>
        <Link to="/allkpop" className="link">
          <div className="title-archive">ALLKPOP</div>
        </Link>
        <Link to="/elestoque" className="link">
          <div className="title-archive">EL ESTOQUE</div>
        </Link>
        <Link to="/joy" className="link">
          <div className="title-archive">JOURNALIST OF THE YEAR</div>
        </Link>
        <Link to="/projects" className="link">
          <div className="title-archive">PROJECTS</div>
        </Link>
        <div style={{ paddingBottom: "3%" }}></div>
      </div>

      <FooterLight />
      <div style={{ paddingBottom: "2%" }}></div>
    </div>
  );
};

export default Archive;
