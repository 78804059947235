import React, { useState, useEffect } from "react";
import "./Home.css";
import HeaderDark from "../../HeaderDark";
import { Pressable } from "react-native";

export const Home = () => {
  const [background, setBackground] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  const generateColor = () => {
    const randomColor = Math.floor(Math.random() * 16777215)
      .toString(16)
      .padStart(6, "0");
    return `#${randomColor}`;
  };

  useEffect(() => {
    async function getWindowSize() {
      try {
        const width = window.innerWidth;
        if (width <= 480) {
          setIsMobile(true);
        } else {
          setIsMobile(false);
        }
      } catch (error) {
        console.error(error);
      }
    }
    getWindowSize();
  }, []);

  return (
    <div className="home">
      {!isMobile ? (
        <>
          <HeaderDark />
          <main>
            <section
              id="landing-page"
              style={{ backgroundColor: background }}
              className="section-landing-page"
            >
              <div className="inflatable-letter-container">
                <img
                  src={require("../../images/H_C.webp")}
                  className="inflatable-letter"
                  alt="letter"
                ></img>
                <img
                  src={require("../../images/A_B.webp")}
                  className="inflatable-letter"
                  alt="letter"
                ></img>
                <img
                  src={require("../../images/N_C.webp")}
                  className="inflatable-letter"
                  alt="letter"
                ></img>
                <img
                  src={require("../../images/N_C.webp")}
                  className="inflatable-letter"
                  alt="letter"
                ></img>
                <img
                  src={require("../../images/A_B.webp")}
                  className="inflatable-letter"
                  alt="letter"
                ></img>
                <img
                  src={require("../../images/H_C.webp")}
                  className="inflatable-letter"
                  alt="letter"
                ></img>
              </div>
              <div style={{ paddingBottom: "5%" }}></div>
              <Pressable onPress={() => setBackground(generateColor())}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="4vw"
                  height="4vw"
                  color="white"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M12 20q-3.35 0-5.675-2.325Q4 15.35 4 12q0-3.35 2.325-5.675Q8.65 4 12 4q1.725 0 3.3.713q1.575.712 2.7 2.037V4h2v7h-7V9h4.2q-.8-1.4-2.187-2.2Q13.625 6 12 6Q9.5 6 7.75 7.75T6 12q0 2.5 1.75 4.25T12 18q1.925 0 3.475-1.1T17.65 14h2.1q-.7 2.65-2.85 4.325Q14.75 20 12 20Z"
                  />
                </svg>
              </Pressable>

              {/* <h2 className="opening-text">Hannah Lee</h2> */}
            </section>
            {/* <section id="about-page" className="section-about-page">
      <ul className="about-list">
        <li className="about-list-items">
          <p className="about-text-indent">Hannah Lee is a</p>
        </li>
        <br />

        <li className="about-list-items">
          <p class="about-text-noindent">designer</p>
          <p class="about-text-noindent">strategist</p>
          <p class="about-text-noindent">creative</p>
          <p class="about-text-noindent">developer</p>
        </li>
        <br />

        <li className="about-list-items">
          <p className="about-text-noindent">dedicated to finding</p>
        </li>
        <li className="about-list-items">
          <p className="about-text-indent">creative solutions.</p>
        </li>
      </ul>
    </section>
    <section id="quote-page" className="section-quote-page">
      <h2 className="quote-text">
        Without strategy, the difference between you and everyone else is
        like <span className="blur-text">m</span>
        <span className="blur-text">a</span>
        <span className="blur-text">g</span>
        <span className="blur-text">i</span>
        <span className="blur-text">c</span>
      </h2>
    </section>
    <section id="contact-page" className="section-contact-page">ㅋ
      <h2 className="contact-main-text">
        <span className="contact-small-text">say hello </span>
        <br />
        hhannah<span className="contact-blur-text">@stanford.edu</span>
      </h2>
    </section> */}
          </main>
        </>
      ) : (
        <div>
          <section
            id="landing-page"
            style={{ backgroundColor: background }}
            className="section-landing-page"
          >
            <div className="inflatable-letter-container">
              <img
                src={require("../../images/H_C.webp")}
                className="inflatable-letter"
                alt="letter"
              ></img>
              <img
                src={require("../../images/A_B.webp")}
                className="inflatable-letter"
                alt="letter"
              ></img>
              <img
                src={require("../../images/N_C.webp")}
                className="inflatable-letter"
                alt="letter"
              ></img>
              <img
                src={require("../../images/N_C.webp")}
                className="inflatable-letter"
                alt="letter"
              ></img>
              <img
                src={require("../../images/A_B.webp")}
                className="inflatable-letter"
                alt="letter"
              ></img>
              <img
                src={require("../../images/H_C.webp")}
                className="inflatable-letter"
                alt="letter"
              ></img>
            </div>
            <div style={{ paddingBottom: "5%" }}></div>
            <Pressable onPress={() => setBackground(generateColor())}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="4vw"
                height="4vw"
                color="white"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M12 20q-3.35 0-5.675-2.325Q4 15.35 4 12q0-3.35 2.325-5.675Q8.65 4 12 4q1.725 0 3.3.713q1.575.712 2.7 2.037V4h2v7h-7V9h4.2q-.8-1.4-2.187-2.2Q13.625 6 12 6Q9.5 6 7.75 7.75T6 12q0 2.5 1.75 4.25T12 18q1.925 0 3.475-1.1T17.65 14h2.1q-.7 2.65-2.85 4.325Q14.75 20 12 20Z"
                />
              </svg>
            </Pressable>
            <h2 className="mobile-text">Please view website on desktop</h2>
          </section>
        </div>
      )}
    </div>
  );
};

export default Home;

// const element = <HeaderDark color={background} />;
