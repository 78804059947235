import React from "react";
import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Menu from "./Menu";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Portfolio from "./pages/Portfolio/Portfolio";
import Photos from "./pages/Photos/Photos";
import Archive from "./pages/Archive/Archive";
import Contact from "./pages/Contact/Contact";
import Cake from "./portfolio/Cake";
import Cliquerie from "./portfolio/Cliquerie";
import Tumblr from "./portfolio/Tumblr";
import Picnic from "./portfolio/Picnic";
import Joy from "./archive/Joy";
import Allkpop from "./archive/Allkpop";
import ElEstoque from "./archive/ElEstoque";
import Projects from "./archive/Projects";
import NCT from "./artists/NCT";
import StrayKids from "./artists/StrayKids";
import BewhY from "./artists/BewhY";
import Seventeen from "./artists/Seventeen";
import AOMG from "./artists/AOMG";
import Amber from "./artists/Amber";
import Keshi from "./artists/Keshi";
import LoveAlarm from "./portfolio/LoveAlarm";

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/journalism" element={<Home />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/about" element={<About />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/photos" element={<Photos />} />
          <Route path="/archive" element={<Archive />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/lovealarm" element={<LoveAlarm />} />
          <Route path="/cake" element={<Cake />} />
          <Route path="/cliquerie" element={<Cliquerie />} />
          <Route path="/tumblr" element={<Tumblr />} />
          <Route path="/picnic" element={<Picnic />} />
          <Route path="/joy" element={<Joy />} />
          <Route path="/allkpop" element={<Allkpop />} />
          <Route path="/elestoque" element={<ElEstoque />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/nct" element={<NCT />} />
          <Route path="/straykids" element={<StrayKids />} />
          <Route path="/bewhy" element={<BewhY />} />
          <Route path="/seventeen" element={<Seventeen />} />
          <Route path="/aomg" element={<AOMG />} />
          <Route path="/amberliu" element={<Amber />} />
          <Route path="/keshi" element={<Keshi />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
