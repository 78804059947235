import React from "react";
import "./PortfolioPage.css";
import HeaderLight from "../HeaderLight";
import FooterLight from "../FooterLight";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export const Cake = () => {
  return (
    <div className="main">
      <HeaderLight />
      <div className="section">
        <div className="upper-title">© CAKE</div>
        <div className="preview">Revolutionizing the way we shop</div>
        <div className="bottom-details">2021 &nbsp; — &nbsp; Hannah Lee</div>
        <img
          src={require("../images/CAKE/CAKE-03.png")}
          className="main-slide"
        ></img>
        <div className="slides-container">
          <Carousel
            centerMode={true}
            centerSlidePercentage={30}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            autoPlay={true}
            interval={2000}
          >
            <img
              src={require("../images/CAKE/CAKE-01.png")}
              className="slide"
            ></img>
            <img
              src={require("../images/CAKE/CAKE-02.png")}
              className="slide"
            ></img>
            <img
              src={require("../images/CAKE/CAKE-04.png")}
              className="slide"
            ></img>
            <img
              src={require("../images/CAKE/CAKE-05.png")}
              className="slide"
            ></img>
            <img
              src={require("../images/CAKE/CAKE-06.png")}
              className="slide"
            ></img>
            <img
              src={require("../images/CAKE/CAKE-07.png")}
              className="slide"
            ></img>
            <img
              src={require("../images/CAKE/CAKE-08.png")}
              className="slide"
            ></img>
            <img
              src={require("../images/CAKE/CAKE-09.png")}
              className="slide"
            ></img>
            <img
              src={require("../images/CAKE/CAKE-10.png")}
              className="slide"
            ></img>
            <img
              src={require("../images/CAKE/CAKE-11.png")}
              className="slide"
            ></img>
            <img
              src={require("../images/CAKE/CAKE-12.png")}
              className="slide"
            ></img>
            <img
              src={require("../images/CAKE/CAKE-13.png")}
              className="slide"
            ></img>
            <img
              src={require("../images/CAKE/CAKE-14.png")}
              className="slide"
            ></img>
          </Carousel>
        </div>
        <div className="indiv-description-container">
          <div className="indiv-description">
            Finding the perfect outfit is hard. Tracking orders from multiple
            stores is even harder. Purchasing the ideal gift? Let's not even
            talk about it. These seemingly simple everyday actions are
            overly-complicated in this day and age and yet, there is still no
            real solution to these problems.
            <br />
            <br />
            That's why I designed CAKE, your one-stop shop for everything for —
            well, everything. CAKE goes above and beyond — it allows you to:
            <ul>
              <li className="bulleted-list">
                <em>Purchase</em> that cute beanie you saw Kendall Jenner
                wearing on Instagram
              </li>
              <li className="bulleted-list">
                <em>Create</em> polls when you're debating
              </li>
              <li className="bulleted-list">
                <em>See</em> real product reviews on real people
              </li>
              <li className="bulleted-list">
                <em>Curate</em> a wishlist you can share with friends and family
              </li>
              <li className="bulleted-list">
                <em>Send</em> meaningful gifts to friends (and have it arrive at
                their doorstep!)
              </li>
              <li className="bulleted-list">
                <em>Shop</em> from multiple stores and track multiple orders all
                in just one app
              </li>
            </ul>
            <hr></hr>
            <span
              style={{
                fontSize: "1.2vw",
                fontWeight: 400,
              }}
            >
              Figma &nbsp; <span>&#183;</span> &nbsp; Adobe Illustrator
              <br />
              <span style={{ fontStyle: "italic" }}>
                For more information about this project, please contact
                hhannah@stanford.edu
              </span>
            </span>
          </div>
        </div>
      </div>
      <FooterLight />
      <div style={{ paddingBottom: "2%" }}></div>
    </div>
  );
};

export default Cake;
