import React from "react";
import "./PortfolioPage.css";
import HeaderLight from "../HeaderLight";
import FooterLight from "../FooterLight";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export const Tumblr = () => {
  return (
    <div className="main">
      <HeaderLight />
      <div className="section">
        <div className="upper-title">© TUMBLR</div>
        <div className="preview">Introducing a new Tumblr</div>
        <div className="bottom-details">2022 &nbsp; — &nbsp; Hannah Lee</div>
        <img
          src={require("../images/TUMBLR.webp")}
          className="main-slide"
        ></img>
        <div className="slides-container">
          <Carousel
            centerMode={true}
            centerSlidePercentage={30}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            autoPlay={true}
            interval={2000}
          >
            <img
              src={require("../images/TUMBLR/TUMBLR_FOREST_DRIBBLE.png")}
              className="slide"
            ></img>
            <img
              src={require("../images/TUMBLR/TUMBLR_EARTH_DRIBBLE.png")}
              className="slide"
            ></img>
            <img
              src={require("../images/TUMBLR/TUMBLR_CHEESE_DRIBBLE.png")}
              className="slide"
            ></img>
            <img
              src={require("../images/TUMBLR/TUMBLR_WRAPPED_DRIBBLE.png")}
              className="slide"
            ></img>
            <img
              src={require("../images/TUMBLR/TUMBLR_PRESENT_DRIBBLE.png")}
              className="slide"
            ></img>
          </Carousel>
        </div>
        <div className="indiv-description-container">
          <div className="indiv-description">
            In October, Dribbble gave designers a challenge: to design a brand
            new app icon for Tumblr — "home to the internet’s favorite cult
            heroes."
            <br />
            <br />
            I never say no to a challenge, especially when there's a prize for
            the winner. For my submissions, I drew inspiration primarily from
            nature, and from food, and from...
            <br />
            <br />
            <hr></hr>
            <span
              style={{
                fontSize: "1.2vw",
                fontWeight: 400,
              }}
            >
              Adobe Illustrator
              <br />
              <span style={{ fontStyle: "italic" }}>
                For more information about this project, please contact
                hhannah@stanford.edu
              </span>
            </span>
          </div>
        </div>
      </div>
      <FooterLight />
      <div style={{ paddingBottom: "2%" }}></div>
    </div>
  );
};

export default Tumblr;
