import React from "react";
import "./PhotosPage.css";
import HeaderDark from "../HeaderDark";
import FooterDark from "../FooterDark";

export const Amber = () => {
  return (
    <div className="main-photos">
      <HeaderDark />
      <div className="half-section-container">
        <div className="half-section-left">
          <img
            src={require("../images/ALLKPOP/AMBER/A1.png")}
            className="allkpop-half-photo"
          ></img>
          <img
            src={require("../images/ALLKPOP/AMBER/A4.png")}
            className="allkpop-half-photo"
          ></img>
          <img
            src={require("../images/ALLKPOP/AMBER/A2.png")}
            className="allkpop-half-photo"
          ></img>
          <img
            src={require("../images/ALLKPOP/AMBER/A11.png")}
            className="allkpop-half-photo"
          ></img>
          <img
            src={require("../images/ALLKPOP/AMBER/A7.png")}
            className="allkpop-half-photo"
          ></img>
          <img
            src={require("../images/ALLKPOP/AMBER/A12.png")}
            className="allkpop-half-photo"
          ></img>
        </div>
        <div className="half-section-right">
          <img
            src={require("../images/ALLKPOP/AMBER/A3.png")}
            className="allkpop-half-photo"
          ></img>
          <img
            src={require("../images/ALLKPOP/AMBER/A5.png")}
            className="allkpop-half-photo"
          ></img>
          <img
            src={require("../images/ALLKPOP/AMBER/A8.png")}
            className="allkpop-half-photo"
          ></img>
        </div>
      </div>
      <FooterDark />
      <div style={{ paddingBottom: "2%" }}></div>
    </div>
  );
};

export default Amber;
